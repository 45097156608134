
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { ProductInterest } from '../../entities/product-interest.entity';
import ProductInterestService from '../../services/product-interest.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class ProductInterestListComponent extends Vue {
  private productInterests: ProductInterest[] = [];

  private get headers(): any {
    if (this.$permissionAccess.group.isSuperAdmin) {
      return [
        { text: 'Descrição', value: 'description', width: '30%' },
        {
          text: 'Empresa',
          value: 'companyName',
        },
        {
          text: 'Status',
          value: 'activeText',
        },
        {
          text: 'Ações',
          width: '10%',
          align: 'center',
          value: 'actions',
        },
      ];
    }
    return [
      { text: 'Descrição', value: 'description', width: '70%' },
      {
        text: 'Status',
        value: 'activeText',
      },
      {
        text: 'Ações',
        width: '10%',
        align: 'center',
        value: 'actions',
      },
    ];
  }

  private goToFormEdit(productInterest: ProductInterest) {
    this.$router.push({
      name: 'product-interest-edit',
      params: {
        id: productInterest.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'product-interest-create',
    });
  }

  private async getProductInterests() {
    const productInterests = await ProductInterestService.getAllProductInterestsPaginate();
    this.productInterests = productInterests.map(
      (productInterest: ProductInterest) => new ProductInterest(productInterest),
    );
  }

  public async backPagination() {
    if (!ProductInterestService.simplePaginationService.prevLink) return;
    const productInterests = await ProductInterestService.simplePaginationService.prev();
    this.productInterests = productInterests.map(
      (productInterest: ProductInterest) => new ProductInterest(productInterest),
    );
  }

  public async nextPagination() {
    if (!ProductInterestService.simplePaginationService.nextLink) return;
    const productInterests = await ProductInterestService.simplePaginationService.next();
    this.productInterests = productInterests.map(
      (productInterest: ProductInterest) => new ProductInterest(productInterest),
    );
  }

  public async goToFirst() {
    if (!ProductInterestService.simplePaginationService.firstLink) return;
    const productInterests = await ProductInterestService.simplePaginationService.goToFirst();
    this.productInterests = productInterests.map(
      (productInterest: ProductInterest) => new ProductInterest(productInterest),
    );
  }

  private async created() {
    this.getProductInterests();
  }
}
